
import * as glideRuntime$Cy1YpJQ58a from '/data/www/mijn.khn.nl/mijn-khn/releases/87/nuxt/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/glide'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "glide",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['glide']: { provider: glideRuntime$Cy1YpJQ58a, defaults: {"baseURL":"https://app.khn.nl"} }
}
        